<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
    this.getConfigList('urgently_needed_detail');
  },
  methods: {
  },
};
</script>
